@font-face {
    font-family: 'NeueMontreal';
    src: local('NeueMontreal'),
    url(./fonts/NeueMontreal-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'NeueMontreal-medium';
    src: local('NeueMontreal'),
    url(./fonts/NeueMontreal-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'NeueMontreal-bold';
    src: local('NeueMontreal'),
    url(./fonts/NeueMontreal-Bold.ttf) format('truetype');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 400;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'NeueMontreal';
}

.app {
    /* padding: 0 12rem; */
}

a {
    text-decoration: none;
}

.active-nav-link {
    opacity: 1 !important;
    filter: drop-shadow(0 0 1rem #067fef);
    border-top: 3px solid transparent; /* To cancel out margin from bottom border*/
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Validation */
.border-error {
    border-color: red !important;
}

.border-success {
    border-color: green !important;
}

.intl-tel-input {
    width: 100% !important;
    margin-top: 0.4rem;
}

.form-control {
    width: 100%;
    border: 2px solid #d7d7d7;
    border-radius: 0.5rem;
    height: 4rem;
}

.iti--allow-dropdown {
    width: 100%;
    margin-top: 0.4rem;
}

.iti--allow-dropdown input {
    height: 4rem;
    width: 9.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: 2px solid #d7d7d7;
}

.country-dropdown-error input {
    border-color: red;
}

.country-dropdown-success input {
    border-color: green;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

::-webkit-scrollbar {
    width: 0.5rem;
    margin: 0.5rem 0;
    padding: 2rem;
    border-radius: 2rem;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2rem;
    padding: 2rem;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.hide-scrollbar::-webkit-scrollbar {
    height: 0.3rem;
}

/* Hide the logo of the text editor library in the automatic reply frame */
.tox .tox-statusbar__branding svg{
    display: none;
}